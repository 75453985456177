:root {
  --primary: #6a1b9a;
  --primary-light: #9c27b0;
  --primary-dark: #4a148c;
  --success: #10b981;
  --danger: #ef4444;
  --warning: #f59e0b;
  --text-primary: #111827;
  --text-secondary: #4b5563;
  --text-light: #6b7280;
  --background: #f9fafb;
  --surface: #ffffff;
  --border: #e5e7eb;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  --radius-sm: 0.375rem;
  --radius-md: 0.5rem;
  --radius-lg: 1rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --input-disabled: #f3f4f6;
  --input-focus: #ddd6fe;
  --input-hover: #f5f3ff;
  --transition-speed: 0.2s;
  --modal-width: 600px;
  --modal-bg: rgba(0, 0, 0, 0.5);
}

.user-dashboard {
  min-height: 100vh;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
}

.dashboard-header {
  background-color: var(--surface);
  box-shadow: var(--shadow-sm);
  position: sticky;
  top: 0;
  z-index: 100;
  padding: var(--spacing-md) var(--spacing-sm);
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: 0 var(--spacing-sm);
}

.header-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
}

.header-logo {
  height: 32px;
  width: auto;
}

.header-right {
  display: flex;
  gap: var(--spacing-sm);
  width: 100%;
}

.settings-button,
.logout-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  touch-action: manipulation;
  min-height: 44px;
}

.settings-button {
  background-color: var(--background);
  color: var(--text-primary);
}

.logout-button {
  background-color: var(--danger);
  color: white;
}

.dashboard-container {
  padding: var(--spacing-md) var(--spacing-sm);
  width: 100%;
}

.stats-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.stat-card {
  background-color: var(--surface);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border);
}

.stat-content {
  padding: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.stat-icon {
  background-color: var(--primary);
  color: white;
  padding: var(--spacing-sm);
  border-radius: var(--radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

.purchases-table-container {
  background-color: var(--surface);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  margin-top: var(--spacing-lg);
  overflow: hidden;
  border: 1px solid var(--border);
  animation: fadeIn 0.3s ease-out;
}

.table-header {
  padding: var(--spacing-lg);
  border-bottom: 1px solid var(--border);
  background-color: var(--surface);
}

.table-header h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table-responsive th {
  background: var(--primary);
  color: white;
  font-weight: 500;
  text-align: left;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  white-space: nowrap;
  position: sticky;
  top: 0;
}

.table-responsive td {
  padding: 1.25rem 1.5rem;
  font-size: 0.875rem;
  border-bottom: 1px solid var(--border);
  background: var(--surface);
}

.table-responsive tr:last-child td {
  border-bottom: none;
}

.table-responsive tr:hover td {
  background-color: #f8f9ff;
}

.table-responsive tr:nth-child(even) td {
  background-color: #fafbff;
}

.table-responsive tr:hover td {
  background-color: #f3f4ff !important;
}

.mobile-purchase-card {
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--border);
  background-color: var(--surface);
}

.mobile-purchase-card:last-child {
  border-bottom: none;
}

.purchase-card-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-sm);
}

.purchase-card-label {
  font-size: 0.75rem;
  color: var(--text-light);
  font-weight: 500;
}

.purchase-card-value {
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 500;
}

.status-container {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: var(--radius-md);
  font-weight: 500;
  font-size: 0.875rem;
}

.status-container.confirmed {
  background-color: rgba(16, 185, 129, 0.1);
  color: var(--success);
}

.status-container.rejected {
  background-color: rgba(239, 68, 68, 0.1);
  color: var(--danger);
}

.status-container.pending {
  background-color: rgba(245, 158, 11, 0.1);
  color: var(--warning);
}

.settings-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: var(--modal-bg);
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
  animation: modalFadeIn 0.3s ease-out forwards;
  backdrop-filter: blur(4px);
}

.settings-modal {
  background-color: var(--surface);
  width: 100%;
  height: auto;
  max-height: 85vh;
  border-radius: var(--radius-lg) var(--radius-lg) 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-lg);
  animation: modalSlideUp 0.3s ease-out forwards;
  transform: translateY(100%);
}

.modal-header {
  padding: var(--spacing-lg);
  border-bottom: 1px solid var(--border);
  background-color: var(--surface);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  position: relative;
  border-radius: var(--radius-lg) var(--radius-lg) 0 0;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
}

.close-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background-color: var(--background);
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  padding: 0;
}

.close-button:hover {
  background-color: var(--border);
  color: var(--text-primary);
}

.modal-content {
  flex: 1;
  padding: var(--spacing-lg);
  overflow-y: auto;
  background-color: var(--background);
  -webkit-overflow-scrolling: touch;
}

.form-group {
  margin-bottom: var(--spacing-lg);
  position: relative;
}

.form-group label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: var(--spacing-xs);
  color: var(--text-primary);
  transition: color var(--transition-speed) ease;
}

.form-group input {
  width: 100%;
  padding: var(--spacing-md);
  border: 1.5px solid var(--border);
  border-radius: var(--radius-md);
  font-size: 16px;
  -webkit-appearance: none;
  background-color: var(--surface);
  transition: all var(--transition-speed) ease;
  margin: 0;
}

.form-group input:hover:not(:disabled) {
  border-color: var(--primary-light);
  background-color: var(--input-hover);
}

.form-group input:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(106, 27, 154, 0.1);
}

.form-group input:disabled {
  background-color: var(--input-disabled);
  cursor: not-allowed;
  color: var(--text-secondary);
  border-color: var(--border);
}

.settings-form {
  background-color: var(--surface);
  border-radius: var(--radius-md);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border);
  margin-bottom: var(--spacing-xl);
}

.settings-form:last-child {
  margin-bottom: 0;
}

.settings-form h3 {
  color: var(--text-primary);
  font-size: 1.125rem;
  margin: 0 0 var(--spacing-lg);
  font-weight: 600;
}

.submit-button {
  width: 100%;
  padding: var(--spacing-md);
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: var(--radius-md);
  font-weight: 500;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-lg);
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  font-size: 1rem;
}

.submit-button:hover:not(:disabled) {
  background-color: var(--primary-dark);
  transform: translateY(-1px);
}

.submit-button:active:not(:disabled) {
  transform: translateY(0);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: var(--primary-light);
}

.submit-button.loading {
  position: relative;
  color: transparent;
}

.submit-button.loading::after {
  content: '';
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-radius: 50%;
  border-right-color: transparent;
  animation: rotate 1s linear infinite;
}

.loading-container {
  min-height: 100vh;
  padding: var(--spacing-xl);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
  color: var(--text-secondary);
}

.loading-spinner {
  animation: spin 1s linear infinite;
}

.dashboard-footer {
  margin-top: auto;
  padding: var(--spacing-lg) var(--spacing-md);
  text-align: center;
  background-color: var(--surface);
  border-top: 1px solid var(--border);
}

.raffle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  touch-action: manipulation;
  min-height: 44px;
  background-color: var(--success);
  color: white;
  text-decoration: none;
  transition: all var(--transition-speed) ease;
}

.raffle-button:hover {
  background-color: #0d9488;
  transform: translateY(-1px);
}

.raffle-button:active {
  transform: translateY(0);
}

.empty-state {
  padding: 4rem 2rem;
  text-align: center;
  background: var(--surface);
  border-radius: var(--radius-lg);
  color: var(--text-secondary);
}

.empty-state svg {
  width: 48px;
  height: 48px;
  color: var(--text-light);
  margin-bottom: 1rem;
}

.empty-state h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.empty-state p {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalSlideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .header-content {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 var(--spacing-xl);
  }

  .header-left {
    flex-direction: row;
  }

  .header-right {
    width: auto;
  }

  .dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--spacing-xl);
  }

  .stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .table-responsive {
    display: block;
  }

  .mobile-purchase-card {
    display: none;
  }

  .settings-modal-overlay {
    align-items: center;
    padding: var(--spacing-md);
  }

  .settings-modal {
    margin: auto;
    max-width: var(--modal-width);
    max-height: 80vh;
    border-radius: var(--radius-lg);
    transform: translateY(0) scale(0.95);
    animation: modalEnter 0.3s ease-out forwards;
  }

  .modal-content {
    padding: var(--spacing-xl);
  }

  .settings-form {
    padding: var(--spacing-xl);
  }

  @keyframes modalEnter {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .raffle-button,
  .settings-button,
  .logout-button {
    padding: var(--spacing-sm) var(--spacing-md);
  }
}

@media (max-width: 768px) {
  .header-right {
    width: 100%;
    justify-content: space-between;
  }
  
  .raffle-button,
  .settings-button,
  .logout-button {
    flex: 1;
    padding: var(--spacing-sm);
    font-size: 0.75rem;
  }
  
  .raffle-button span,
  .settings-button span,
  .logout-button span {
    display: none;
  }

  .purchases-table-container {
    margin: 0 -1rem;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .table-responsive td,
  .table-responsive th {
    padding: 1rem;
  }

  .status-container {
    padding: 0.375rem 0.625rem;
  }
}

@media (hover: none) {
  .settings-button,
  .logout-button,
  .submit-button,
  .form-group input {
    -webkit-tap-highlight-color: transparent;
  }
}

.form-group.success input {
  border-color: var(--success);
}

.form-group.error input {
  border-color: var(--danger);
}

.error-message {
  color: var(--danger);
  font-size: 0.75rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.form-group input:focus-visible,
.submit-button:focus-visible,
.close-button:focus-visible {
  outline: 2px solid var(--primary);
  outline-offset: 2px;
}

body.modal-open {
  overflow: hidden;
  padding-right: var(--scrollbar-width, 0);
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}