/* src/assets/styles/adminSections/AdminLayout.css */
@import '../base.css';

/* Admin Layout Variables */
:root {
  --sidebar-width: 250px;
  --sidebar-collapsed-width: 80px;
  --sidebar-bg: #2A303C;
  --sidebar-text: #A6ADBA;
  --sidebar-hover: rgba(255, 255, 255, 0.1);
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --sidebar-active: var(--primary);
  --header-height: 64px;
  --transition-speed: 0.3s;
  --bottom-nav-height: 65px;
}

/* Layout Structure */
.admin-layout {
  display: flex;
  min-height: 100vh;
  background-color: var(--background);
}

/* Sidebar Styles */
.sidebar {
  width: var(--sidebar-width);
  height: 100vh;
  background-color: var(--sidebar-bg);
  color: var(--sidebar-text);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  transition: width var(--transition-speed) ease;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);
  z-index: 50;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sidebar.collapsed {
  width: var(--sidebar-collapsed-width);
}

/* Logo Container */
.logo-container {
  height: var(--header-height);
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  border-bottom: 1px solid var(--sidebar-border);
  background-color: rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1;
}

.logo-container span {
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: 0.75rem;
  opacity: 1;
  transition: opacity var(--transition-speed) ease;
}

.collapsed .logo-container span {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

/* Navigation Icons */
.nav-item-icon {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  transition: transform var(--transition-speed) ease;
}

/* Navigation Section */
.nav-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  overflow-y: auto;
}

.nav-section::-webkit-scrollbar {
  width: 4px;
}

.nav-section::-webkit-scrollbar-thumb {
  background-color: var(--sidebar-border);
  border-radius: 4px;
}

/* Navigation Items */
.nav-item {
  display: flex;
  align-items: center;
  padding: 0.875rem 1rem;
  color: var(--sidebar-text);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  border: none;
  background: transparent;
  width: 100%;
  text-align: left;
  position: relative;
}

.nav-item:hover {
  background-color: var(--sidebar-hover);
  color: white;
}

.nav-item.active {
  color: white;
  background-color: var(--sidebar-active);
}

.nav-item.active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 60%;
  background-color: white;
  border-radius: 0 3px 3px 0;
}

.nav-item-text {
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 0.75rem;
  transition: opacity var(--transition-speed) ease;
}

.collapsed .nav-item-text {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

/* User Menu */
.user-menu {
  padding: 1rem;
  border-top: 1px solid var(--sidebar-border);
  margin-top: auto;
}

.user-menu-button {
  display: flex;
  align-items: center;
  padding: 0.875rem 1rem;
  width: 100%;
  color: var(--sidebar-text);
  background: transparent;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
}

.user-menu-button:hover {
  background-color: var(--sidebar-hover);
  color: white;
}

.user-menu-button .nav-item-icon {
  color: #ef4444;
}

/* Main Content Area */
.main-content {
  margin-left: var(--sidebar-width);
  padding: 2rem;
  width: calc(100% - var(--sidebar-width));
  transition: all var(--transition-speed) ease;
  min-height: 100vh;
  background-color: var(--background);
}

.main-content.expanded {
  margin-left: var(--sidebar-collapsed-width);
  width: calc(100% - var(--sidebar-collapsed-width));
}

/* Bottom Navigation */
.bottom-nav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--bottom-nav-height);
  background: var(--sidebar-bg);
  padding: 0.5rem;
  z-index: 49;
  border-top: 1px solid var(--sidebar-border);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.bottom-nav-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
  gap: 0.25rem;
}

.bottom-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--sidebar-text);
  font-size: 0.65rem;
  gap: 0.25rem;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: color var(--transition-speed) ease;
  padding: 0.25rem;
  white-space: nowrap;
  text-align: center;
}

.bottom-nav-item svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: 0.15rem;
}

.bottom-nav-item.active {
  color: var(--primary);
}

.bottom-nav-item:hover {
  color: var(--primary);
}

.bottom-nav-item:active {
  transform: scale(0.95);
}

/* More Menu Styles */
.bottom-nav-more {
  position: relative;
}

.more-menu {
  position: absolute;
  bottom: calc(100% + 0.5rem);
  right: 0;
  background: var(--sidebar-bg);
  border-radius: 0.5rem;
  padding: 0.5rem;
  min-width: 180px;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2);
  transform-origin: bottom right;
  animation: scaleIn 0.2s ease;
  border: 1px solid var(--sidebar-border);
}

.more-menu-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  width: 100%;
  color: var(--sidebar-text);
  background: transparent;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all var(--transition-speed) ease;
}

.more-menu-item:hover {
  background: var(--sidebar-hover);
  color: white;
}

.more-menu-item.active {
  color: var(--primary);
}

.more-menu-divider {
  height: 1px;
  background: var(--sidebar-border);
  margin: 0.5rem 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .bottom-nav {
    display: block;
  }

  .main-content {
    margin-left: 0;
    width: 100%;
    padding: 1rem;
    padding-bottom: calc(var(--bottom-nav-height) + 1rem);
  }

  .main-content.expanded {
    margin-left: 0;
    width: 100%;
  }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .sidebar {
    width: var(--sidebar-collapsed-width);
  }

  .main-content {
    margin-left: var(--sidebar-collapsed-width);
    width: calc(100% - var(--sidebar-collapsed-width));
  }

  .sidebar:hover {
    width: var(--sidebar-width);
  }

  .sidebar:hover .nav-item-text,
  .sidebar:hover .logo-container span {
    opacity: 1;
    width: auto;
  }
}

/* Animations */
@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}