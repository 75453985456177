/* src/assets/styles/adminSections/CreateRaffle.css */
@import '../base.css';

/* Main Container */
.create-raffle {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: var(--background);
}

/* Page Header */
.page-header {
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--border);
  padding-bottom: 1rem;
}

.page-title {
  font-size: 1.875rem;
  font-weight: 600;
  color: var(--card-foreground);
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.page-description {
  color: var(--muted-foreground);
  font-size: 0.875rem;
}

/* Form Container */
.form-container {
  background: var(--card);
  padding: 2rem;
  border-radius: var(--radius);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border);
}

/* Form Layout */
.raffle-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

/* Form Elements */
.form-group label {
  font-weight: 500;
  color: var(--card-foreground);
  font-size: 0.875rem;
}

.form-group input,
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  font-size: 0.875rem;
  transition: all 0.2s ease;
  width: 100%;
  background-color: var(--background);
  color: var(--card-foreground);
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
  line-height: 1.5;
}

/* Input States */
.form-group input:hover,
.form-group textarea:hover {
  border-color: var(--primary);
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(106, 27, 154, 0.1);
}

.form-group input:disabled,
.form-group textarea:disabled {
  background-color: var(--muted);
  cursor: not-allowed;
  opacity: 0.7;
}

.form-group input.invalid,
.form-group textarea.invalid {
  border-color: #ef4444;
  background-color: rgba(239, 68, 68, 0.05);
}

/* File Upload */
.file-upload-container {
  border: 2px dashed var(--border);
  border-radius: var(--radius);
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: var(--background);
}

.file-upload-container:hover {
  border-color: var(--primary);
  background-color: rgba(106, 27, 154, 0.05);
}

.file-upload-container input[type="file"] {
  display: none;
}

.upload-icon {
  color: var(--primary);
  margin-bottom: 0.75rem;
}

.file-upload-container p {
  color: var(--card-foreground);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-muted {
  color: var(--muted-foreground);
}

/* Status Messages */
.status-message {
  padding: 1rem;
  border-radius: var(--radius);
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.875rem;
}

.error-message {
  background-color: #fee2e2;
  border: 1px solid #fecaca;
  color: #991b1b;
}

.success-message {
  background-color: #dcfce7;
  border: 1px solid #bbf7d0;
  color: #166534;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  line-height: 1.5;
}

/* Image Preview */
.image-preview {
  margin-top: 1.5rem;
  border-radius: var(--radius);
  overflow: hidden;
  border: 1px solid var(--border);
  position: relative;
  background-color: var(--background);
}

.image-preview img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  display: block;
}

/* Submit Button */
.submit-button {
  background-color: var(--primary);
  color: white;
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: var(--radius);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
}

.submit-button:hover:not(:disabled) {
  background-color: var(--primary-dark);
  transform: translateY(-1px);
}

.submit-button:active:not(:disabled) {
  transform: translateY(0);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: var(--muted-foreground);
}

/* Loading Spinner */
@keyframes spin {
  to { transform: rotate(360deg); }
}

.spin {
  animation: spin 1s linear infinite;
}

/* Error Text */
.error-text {
  color: #ef4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

/* Helper Text */
.helper-text {
  font-size: 0.75rem;
  color: var(--muted-foreground);
  margin-top: 0.25rem;
}

/* Required Field Indicator */
.required::after {
  content: '*';
  color: #ef4444;
  margin-left: 0.25rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .create-raffle {
    padding: 1rem;
  }

  .form-container {
    padding: 1.5rem;
    border-radius: var(--radius);
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .page-header {
    margin-bottom: 1.5rem;
  }

  .page-title {
    font-size: 1.5rem;
  }

  .file-upload-container {
    padding: 1.5rem;
  }
}

/* Accessibility Focus Styles */
.form-group input:focus-visible,
.form-group textarea:focus-visible,
.submit-button:focus-visible {
  outline: 2px solid var(--primary);
  outline-offset: 2px;
}
