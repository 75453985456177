/* src/assets/styles/base.css */

:root {
  --primary: #6a1b9a;
  --primary-dark: #4a148c;
  --background: #f9fafb;
  --card: #ffffff;
  --card-foreground: #111827;
  --popover: #ffffff;
  --popover-foreground: #111827;
  --muted: #f3f4f6;
  --muted-foreground: #6b7280;
  --border: #e5e7eb;
  --input: #e5e7eb;
  --ring: #6a1b9a;
  --radius: 0.5rem;
}

/* Layout */
.admin-section {
  padding: 1.5rem;
  background-color: var(--background);
  min-height: 100vh;
}

/* Typography */
.page-title {
  font-size: 1.875rem;
  font-weight: 600;
  color: var(--card-foreground);
  margin-bottom: 0.5rem;
}

.page-description {
  color: var(--muted-foreground);
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
}

/* Cards */
.card {
  background: var(--card);
  border-radius: var(--radius);
  border: 1px solid var(--border);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-header {
  padding: 1.5rem;
  border-bottom: 1px solid var(--border);
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--card-foreground);
}

.card-description {
  color: var(--muted-foreground);
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.card-content {
  padding: 1.5rem;
}

.card-footer {
  padding: 1.5rem;
  border-top: 1px solid var(--border);
}

/* Stats Cards */
.stats-card {
  background: var(--card);
  padding: 1.5rem;
  border-radius: var(--radius);
  border: 1px solid var(--border);
}

.stats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.stats-value {
  font-size: 2rem;
  font-weight: bold;
  color: var(--card-foreground);
}

.stats-description {
  font-size: 0.875rem;
  color: var(--muted-foreground);
}

/* Buttons */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: var(--radius);
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.btn-primary {
  background: var(--primary);
  color: white;
  border: none;
}

.btn-primary:hover {
  background: var(--primary-dark);
}

.btn-secondary {
  background: var(--muted);
  color: var(--muted-foreground);
  border: 1px solid var(--border);
}

.btn-secondary:hover {
  background: var(--border);
}

.btn-danger {
  background: #ef4444;
  color: white;
  border: none;
}

.btn-danger:hover {
  background: #dc2626;
}

/* Forms */
.form-group {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--card-foreground);
  margin-bottom: 0.5rem;
}

.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--input);
  border-radius: var(--radius);
  font-size: 0.875rem;
  transition: border-color 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: var(--ring);
  box-shadow: 0 0 0 2px rgba(106, 27, 154, 0.1);
}

/* Tables */
.table-container {
  overflow-x: auto;
  border-radius: var(--radius);
  border: 1px solid var(--border);
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th {
  background: var(--muted);
  color: var(--muted-foreground);
  font-weight: 500;
  text-align: left;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
}

.table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border);
  font-size: 0.875rem;
}

.table tr:last-child td {
  border-bottom: none;
}

/* Status Badges */
.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-success {
  background: #dcfce7;
  color: #166534;
}

.status-warning {
  background: #fef3c7;
  color: #92400e;
}

.status-error {
  background: #fee2e2;
  color: #991b1b;
}

/* Loading States */
.loading-spinner {
  border: 3px solid var(--muted);
  border-top: 3px solid var(--primary);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Grid Layouts */
.grid-cols-1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

/* Responsive Design */
@media (max-width: 640px) {
  .admin-section {
    padding: 1rem;
  }

  .page-title {
    font-size: 1.5rem;
  }

  .card-header,
  .card-content,
  .card-footer {
    padding: 1rem;
  }

  .table-container {
    margin: 0 -1rem;
    border-radius: 0;
  }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--muted);
}

::-webkit-scrollbar-thumb {
  background: var(--muted-foreground);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

/* Transitions */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

/* Icons */
.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.icon-sm {
  width: 1rem;
  height: 1rem;
}

.icon-lg {
  width: 1.5rem;
  height: 1.5rem;
}