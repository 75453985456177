@import '../base.css';

.dashboard-overview {
  padding: var(--content-padding, 1.5rem);
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.refresh-button {
  padding: 0.5rem 1rem;
  background: var(--primary);
  color: white;
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.refresh-button:hover {
  background: var(--primary-dark);
}

.summary-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

.summary-card {
  background: var(--card);
  border-radius: var(--radius);
  padding: 1.25rem;
  transition: all 0.2s ease;
  border: 1px solid var(--border);
  height: 100%;
}

.summary-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.summary-card.blue { border-left: 4px solid #2196f3; }
.summary-card.green { border-left: 4px solid #4caf50; }
.summary-card.purple { border-left: 4px solid var(--primary); }

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.card-title {
  font-size: 0.875rem;
  color: var(--muted-foreground);
  font-weight: 500;
}

.card-header svg {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--muted-foreground);
}

.card-content {
  margin-bottom: 1rem;
}

.card-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--card-foreground);
  margin-bottom: 0.25rem;
}

.card-change {
  font-size: 0.875rem;
  color: var(--success, #4caf50);
}

.card-sub-stats {
  padding-top: 1rem;
  border-top: 1px solid var(--border);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.sub-stat {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.sub-stat-label {
  font-size: 0.75rem;
  color: var(--muted-foreground);
}

.sub-stat-value {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--card-foreground);
}

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  gap: 1rem;
}

.loading-spinner {
  border: 3px solid var(--muted);
  border-top: 3px solid var(--primary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  gap: 1rem;
}

.error-message {
  color: var(--destructive);
  text-align: center;
  padding: 1rem;
  background: var(--destructive-light);
  border-radius: var(--radius);
  width: 100%;
  max-width: 400px;
}

.retry-button {
  padding: 0.5rem 1rem;
  background: var(--destructive);
  color: white;
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.retry-button:hover {
  background: var(--destructive-dark);
}

/* Pending Payments Section */
.pending-payments-section {
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--muted, #f9f9f9);
  border-radius: var(--radius);
}

.pending-payments-section h3 {
  margin-bottom: 1rem;
  color: var(--muted-foreground);
  font-weight: 600;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 -1rem;
  padding: 0 1rem;
}

.pending-payments-table {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
  background: white;
  border-radius: var(--radius);
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pending-payments-table th,
.pending-payments-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--border, #ddd);
  font-size: 0.875rem;
}

.pending-payments-table th {
  background-color: var(--muted, #e0e0e0);
  font-weight: bold;
  color: var(--foreground, #333);
  white-space: nowrap;
}

.pending-payments-table tr:hover {
  background-color: var(--muted, #f1f1f1);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .dashboard-overview {
    padding: 1rem;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: stretch;
  }

  .refresh-button {
    width: 100%;
    justify-content: center;
  }

  .summary-cards {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .summary-card {
    padding: 1rem;
  }

  .card-value {
    font-size: 1.25rem;
  }

  .card-header svg {
    width: 1rem;
    height: 1rem;
  }

  .card-sub-stats {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .sub-stat {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--border);
  }

  .sub-stat:last-child {
    border-bottom: none;
  }

  .table-container {
    margin: 0 -1rem;
    padding: 0 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .summary-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1025px) {
  .card-value {
    font-size: 1.875rem;
  }
}