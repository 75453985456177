/* ==========================================================================
   Login Page Styles
   ========================================================================== */

/* Base Container Styles
   ========================================================================== */
   .login-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #6a1b9a 0%, #4a148c 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
  }
  
  .login-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .login-box {
    background: rgba(255, 255, 255, 0.98);
    border-radius: 16px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    padding: 2.5rem;
    animation: fadeIn 0.5s ease-out;
  }
  
  /* Logo and Header Styles
     ========================================================================== */
  .logo-section {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .login-logo {
    max-width: 140px;
    height: auto;
  }
  
  .login-title {
    color: #1a1a1a;
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  .login-subtitle {
    color: #666;
    font-size: 0.875rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  /* Form Styles
     ========================================================================== */
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  
  /* Input Group Styles */
  .form-group {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .input-with-icon {
    position: relative;
  }
  
  /* Icon Styles */
  .input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s ease, color 0.2s ease, transform 0.2s ease;
    color: #6b7280;
    pointer-events: none;
    z-index: 2;
  }
  
  /* Input Field Styles */
  .form-group input {
    width: 100%;
    height: 48px;
    padding: 0.875rem 1rem 0.875rem 3rem;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 0.875rem;
    color: #1a1a1a;
    background: white;
    transition: all 0.2s ease, padding 0.3s ease;
  }
  
  /* Placeholder Styles */
  .form-group input::placeholder {
    color: #9ca3af;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  /* Hide placeholder and adjust padding when input has value */
  .form-group input:not(:placeholder-shown) {
    padding-left: 1rem;
  }
  
  /* Hide icon when input has value */
  .form-group input:not(:placeholder-shown) + .input-icon {
    opacity: 0;
    transform: translateY(-50%) translateX(-10px);
  }
  
  /* Hide placeholder when typing */
  .form-group input:focus::placeholder {
    opacity: 0;
  }
  
  /* Input States */
  .form-group input:focus {
    outline: none;
    border-color: #6a1b9a;
    box-shadow: 0 0 0 3px rgba(106, 27, 154, 0.1);
  }
  
  .form-group input:focus + .input-icon {
    color: #6a1b9a;
  }
  
  .form-group input.error {
    border-color: #dc2626;
  }
  
  /* Button Styles
     ========================================================================== */
  .login-button {
    background: #6a1b9a;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.875rem;
    font-size: 0.875rem;
    font-weight: 500;
    width: 100%;
    height: 48px;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    min-height: 48px;
  }
  
  .login-button .loading-state span {
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  /* Button States */
  .login-button:hover:not(:disabled) {
    background: #4a148c;
    transform: translateY(-1px);
  }
  
  .login-button:active:not(:disabled) {
    transform: translateY(0);
  }
  
  .login-button:disabled {
    background: #9c4dcc;
    cursor: not-allowed;
  }
  
  /* Error Alert Styles
     ========================================================================== */
  .error-alert {
    background-color: #fef2f2;
    border: 1px solid #fee2e2;
    border-radius: 8px;
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #dc2626;
    font-size: 0.875rem;
  }
  
  .error-icon {
    width: 20px;
    height: 20px;
    fill: #dc2626;
    flex-shrink: 0;
  }
  
  /* Loading State Styles
     ========================================================================== */
  .loading-state {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  }
  
  .loading-spinner {
    width: 20px;
    height: 20px;
    position: relative;
    margin: 0;
  }
  
  .loading-spinner::before,
  .loading-spinner::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    animation: pulse 1.8s ease-in-out infinite;
  }
  
  .loading-spinner::before {
    width: 100%;
    height: 100%;
    background: white;
    animation-delay: -0.5s;
  }
  
  .loading-spinner::after {
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.5;
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(0);
      opacity: 1;
    }
    50% {
      transform: scale(1);
      opacity: 0.25;
    }
  }
  
  /* Footer Styles
     ========================================================================== */
  .login-footer {
    margin-top: 2rem;
    text-align: center;
    color: #6b7280;
    font-size: 0.75rem;
  }
  
  /* Animations
     ========================================================================== */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Media Queries
     ========================================================================== */
  @media (max-width: 480px) {
    .login-box {
      padding: 2rem;
    }
  
    .login-title {
      font-size: 1.5rem;
    }
  
    .login-logo {
      max-width: 120px;
    }
  
    .form-group input {
      font-size: 16px;
    }
  }
  
  /* Accessibility
     ========================================================================== */
  .form-group input:focus-visible {
    outline: 2px solid #6a1b9a;
    outline-offset: 1px;
  }
  
  .form-group label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  /* Dark Mode Support
     ========================================================================== */
  @media (prefers-color-scheme: dark) {
    .login-box {
      background: rgba(255, 255, 255, 0.95);
    }
  }