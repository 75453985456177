/* PaymentDetailsPage.css */

.payment-details-page {
    min-height: 100vh;
    padding: 2rem 1rem;
    background-color: #f8fafc;
}

.payment-container {
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 2rem;
}

.payment-container h1 {
    text-align: center;
    color: #1a202c;
    font-size: 1.875rem;
    font-weight: 600;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #e2e8f0;
}

.payment-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

@media (min-width: 1024px) {
    .payment-content {
        grid-template-columns: 3fr 2fr;
    }
}

.payment-details h3 {
    color: #2d3748;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-align: left;
}

.payment-method-details {
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.account-info-highlight {
    background: white;
    border: 2px solid #6b46c1;
    border-radius: 8px;
    padding: 1rem;
}

.info-row {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-bottom: 1px dashed #e2e8f0;
}

.info-row:last-child {
    border-bottom: none;
}

.info-row .label {
    width: 150px;
    color: #4a5568;
    font-weight: 500;
}

.info-row .value {
    flex: 1;
    font-family: 'Courier New', monospace;
    font-weight: 600;
    color: #2d3748;
}

.copy-button {
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #64748b;
    font-size: 0.875rem;
}

.copy-button:hover {
    background: #e2e8f0;
    border-color: #cbd5e0;
    color: #475569;
}

.qr-code {
    display: block;
    width: 150px;
    height: 150px;
    margin: 1.5rem auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.exchange-rate-info {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e2e8f0;
    text-align: center;
}

.exchange-rate {
    color: #6b46c1;
    font-weight: 600;
    margin: 0;
}

.exchange-rate.loading {
    color: #718096;
}

.exchange-rate.error {
    color: #e53e3e;
    font-size: 0.875rem;
}

/* Summary Styles */
.summary-container {
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 1.5rem;
    margin-top: 2rem;
}

.numbers-section h4 {
    color: #2d3748;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.numbers-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.number-badge {
    background: #6b46c1;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
}

.price-details {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #e2e8f0;
}

.price-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    color: #4a5568;
}

.price-row.total {
    border-top: 2px solid #e2e8f0;
    margin-top: 0.5rem;
    padding-top: 0.75rem;
    font-weight: 600;
    color: #2d3748;
}

.price-row.total.bs {
    font-size: 1.1em;
    font-weight: bold;
    color: #6b46c1;
}

.price-row.reference {
    font-size: 0.9em;
    color: #666;
    border-top: 1px dashed #e2e8f0;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
}

.reference-value {
    opacity: 0.8;
    font-size: 0.9em;
    font-family: 'Courier New', monospace;
    color: #666;
}

/* Form Styles */
.payment-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-section {
    margin-bottom: 2rem;
}

.form-section h3 {
    color: #2d3748;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.section-description {
    color: #718096;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    color: #4a5568;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.form-group input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.form-group input::placeholder {
    color: #9ca3af;
}

.form-group input:focus {
    outline: none;
    border-color: #6b46c1;
    box-shadow: 0 0 0 3px rgba(107, 70, 193, 0.1);
}

.validation-message {
    color: #e53e3e;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    display: block;
}

/* Upload Styles */
.upload-container {
    margin-top: 1rem;
}

.upload-label {
    display: block;
    padding: 2rem;
    background: #f8fafc;
    border: 2px dashed #cbd5e0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: center;
}

.upload-label:hover {
    border-color: #6b46c1;
    background: #edf2f7;
}

.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.upload-icon {
    font-size: 2rem;
}

.file-input {
    display: none;
}

/* Button Styles */
.report-button,
.submit-button {
    width: 100%;
    padding: 0.875rem;
    background: #6b46c1;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.report-button:hover,
.submit-button:hover:not(:disabled) {
    background: #553c9a;
    transform: translateY(-1px);
}

.submit-button:disabled {
    background: #a0aec0;
    cursor: not-allowed;
}

/* Loading States */
.loading-text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.loading-spinner {
    width: 1rem;
    height: 1rem;
    border: 2px solid white;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

/* Error Message */
.error-message {
    background: #fed7d7;
    border: 1px solid #feb2b2;
    color: #c53030;
    padding: 1rem;
    border-radius: 6px;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 500;
}

/* Instructions Box */
.instructions-box {
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
}

.instructions-box h3 {
    color: #2d3748;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
}

.instructions-box p {
    color: #4a5568;
    font-size: 0.875rem;
    line-height: 1.5;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .payment-container {
        padding: 1rem;
    }

    .info-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .info-row .label {
        width: 100%;
    }

    .copy-button {
        width: 100%;
        margin: 0.5rem 0 0;
    }
}

@media (max-width: 480px) {
    .payment-details-page {
        padding: 1rem 0.5rem;
    }

    .payment-container h1 {
        font-size: 1.5rem;
    }

    .form-section {
        padding: 1rem;
    }
}

/* Enhanced Payment Summary Styles */
.payment-summary {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.summary-header {
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
}

.summary-header h3 {
  color: #1a202c;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.summary-header .divider {
  height: 2px;
  background: linear-gradient(to right, transparent, #6b46c1, transparent);
  margin: 0.75rem auto;
  width: 80%;
}

.summary-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Numbers Section */
.numbers-section {
  background-color: #f8fafc;
  padding: 1.25rem;
  border-radius: 10px;
  border: 1px solid #e2e8f0;
}

.section-title {
  color: #4a5568;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.numbers-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
  padding: 0.5rem;
}

.number-badge {
  background-color: #6b46c1;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.95rem;
  font-weight: 600;
  min-width: 3rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(107, 70, 193, 0.2);
  transition: transform 0.2s ease;
}

.number-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(107, 70, 193, 0.3);
}

/* Price Details */
.price-details {
  background-color: #f8fafc;
  padding: 1.25rem;
  border-radius: 10px;
  border: 1px solid #e2e8f0;
}

.price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  color: #4a5568;
  font-size: 0.95rem;
}

.price-row:not(:last-child) {
  border-bottom: 1px dashed #e2e8f0;
}

.price-row .value {
  font-family: 'Courier New', monospace;
  font-weight: 600;
  color: #2d3748;
  background: rgba(107, 70, 193, 0.05);
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
}

.price-row.total {
  border-top: 2px solid #e2e8f0;
  margin-top: 0.75rem;
  padding-top: 1rem;
  font-weight: 600;
  color: #1a202c;
  font-size: 1.1rem;
}

.price-row.total.bs {
  color: #6b46c1;
}

.total-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.25rem;
}

.main-amount {
  font-size: 1.4rem;
  font-weight: 700;
  color: #6b46c1;
  background: rgba(107, 70, 193, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.reference-amount {
  font-size: 0.85rem;
  color: #718096;
  font-weight: 500;
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .payment-summary {
    padding: 1rem;
  }

  .numbers-grid {
    gap: 0.5rem;
  }

  .number-badge {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
  }

  .price-row {
    font-size: 0.875rem;
  }

  .main-amount {
    font-size: 1.25rem;
    padding: 0.375rem 0.75rem;
  }

  .reference-amount {
    font-size: 0.75rem;
  }
}
