/* General App Styles */
.app {
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #333;
}

/* Content Area */
.content {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 20px;
}

/* Admin Dashboard Styles */
.admin-dashboard {
  display: flex;
}

/* Sidebar Styles */
.sidebar {
  background-color: #343a40;
  color: white;
  width: 250px;
  padding: 15px;
  height: 100vh;
  position: fixed;
}

.sidebar-header h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 1.5rem;
  text-align: center;
}

.sidebar-menu {
  list-style: none;
  padding-left: 0;
}

.sidebar-menu li {
  margin-bottom: 20px;
}

.sidebar-menu li a {
  color: #c2c7d0;
  font-size: 16px;
  text-decoration: none;
  display: block;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sidebar-menu li a:hover {
  background-color: #495057;
}

.sidebar-menu li.active a {
  background-color: #007bff;
  color: white;
}

/* Form Styles */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.form-group input[type="file"] {
  padding: 3px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Table Styles */
.table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.table th {
  background-color: #007bff;
  color: white;
}

.table tr:hover {
  background-color: #f1f1f1;
}

.table .btn {
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 5px;
}

/* Sales Table Specific Styles */
.sales-section table {
  margin-top: 20px;
}

.sales-section .btn-success {
  background-color: #28a745;
}

.sales-section .btn-danger {
  background-color: #dc3545;
}

/* Raffle Section Specific Styles */
.raffle-section {
  padding: 20px;
}

.raffle-section form {
  max-width: 600px;
  margin: 0 auto;
}

/* User Section Specific Styles */
.user-section {
  padding: 20px;
}

.user-section table {
  margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .admin-dashboard {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .content {
    margin: 0;
    padding: 10px;
  }

  .sidebar-menu li {
    text-align: center;
  }
}
