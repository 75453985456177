/* src/assets/styles/adminSections/PendingPayments.css */
@import '../base.css';

/* Main Container */
.pending-payments {
  padding: 1.5rem;
}

/* Page Header */
.page-header {
  margin-bottom: 2rem;
}

.page-title {
  font-size: 1.875rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.5rem;
}

.page-description {
  color: #6b7280;
  font-size: 0.875rem;
}

/* Loading State */
.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 40;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  position: relative;
}

.loading-spinner::before,
.loading-spinner::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  animation: pulse 1.8s ease-in-out infinite;
}

.loading-spinner::before {
  width: 100%;
  height: 100%;
  background: var(--primary);
  animation-delay: -0.5s;
}

.loading-spinner::after {
  width: 100%;
  height: 100%;
  background: var(--primary);
  opacity: 0.5;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 0.25;
  }
}

.loading-text {
  color: var(--primary);
  font-size: 0.875rem;
  font-weight: 500;
}

/* Messages */
.message {
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.error-message {
  background: #fee2e2;
  border: 1px solid #fecaca;
  color: #991b1b;
}

.success-message {
  background: #dcfce7;
  border: 1px solid #bbf7d0;
  color: #166534;
}

/* Table Container */
.payments-table-container {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  border: 1px solid #e5e7eb;
}

/* Table Styles */
.payments-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.payments-table th {
  background: #6a1b9a;
  color: white;
  text-align: left;
  padding: 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  white-space: nowrap;
}

.payments-table th:first-child {
  border-top-left-radius: 0.5rem;
}

.payments-table th:last-child {
  border-top-right-radius: 0.5rem;
}

.payments-table td {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  font-size: 0.875rem;
}

.payments-table tr:hover td {
  background-color: #f9fafb;
}

/* Status Badge */
.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-pending {
  background: #fff3c7;
  color: #92400e;
}

.status-confirmed {
  background: #dcfce7;
  color: #166534;
}

.status-rejected {
  background: #fee2e2;
  color: #991b1b;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
}

.view-button {
  @extend .button;
  background: #6a1b9a;
  color: white;
}

.view-button:hover {
  background: #4a148c;
  transform: translateY(-1px);
}

.view-button:active {
  transform: translateY(0);
}

/* Modal */
.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 50;
  backdrop-filter: blur(4px);
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.modal-header {
  margin-bottom: 1.5rem;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
}

/* Payment Details */
.payment-details {
  display: grid;
  gap: 1.5rem;
}

.detail-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.detail-group label {
  font-weight: 500;
  color: #374151;
  font-size: 0.875rem;
}

.detail-group p {
  color: #111827;
}

.proof-image-container {
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  overflow: hidden;
}

.proof-image {
  width: 100%;
  height: auto;
  display: block;
}

.proof-image-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.proof-image-container:hover .proof-image-overlay {
  opacity: 1;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.confirm-button,
.reject-button,
.cancel-button {
  @extend .button;
  flex: 1;
  justify-content: center;
}

.confirm-button {
  background: #6a1b9a;
  color: white;
}

.confirm-button:hover:not(:disabled) {
  background: #4a148c;
}

.reject-button {
  background: #ef4444;
  color: white;
}

.reject-button:hover:not(:disabled) {
  background: #dc2626;
}

.cancel-button {
  background: #e5e7eb;
  color: #374151;
}

.cancel-button:hover:not(:disabled) {
  background: #d1d5db;
}

/* Disabled State */
button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}

/* Empty State */
.no-payments {
  text-align: center;
  padding: 3rem;
  color: #6b7280;
  background: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.no-payments-icon {
  color: #d1d5db;
  width: 48px;
  height: 48px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pending-payments {
    padding: 1rem;
  }

  .payments-table-container {
    margin: 0 -1rem;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .payments-table th,
  .payments-table td {
    padding: 0.75rem;
  }

  .modal-content {
    padding: 1.5rem;
    margin: 1rem;
    max-height: calc(100vh - 2rem);
  }

  .modal-actions {
    flex-direction: column;
  }

  .action-buttons {
    flex-direction: column;
    width: 100%;
  }

  .button {
    width: 100%;
    justify-content: center;
  }
}

/* Print Styles */
@media print {
  .pending-payments {
    padding: 0;
  }

  .action-buttons,
  .modal-actions {
    display: none;
  }

  .payments-table {
    border: 1px solid #e5e7eb;
  }

  .payments-table th {
    background: #f3f4f6 !important;
    color: #111827 !important;
  }
}

/* Existing styles remain the same until the modal section */
.payments-header {
  margin-bottom: 2rem;
}

.payments-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.payment-card {
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e7eb;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 50;
  backdrop-filter: blur(4px);
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Rejection Dialog Styles */
.rejection-form {
  margin: 1.5rem 0;
  background: #f9fafb;
  padding: 1.5rem;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
}

.rejection-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #374151;
  font-size: 0.875rem;
}

.rejection-textarea {
  width: 100%;
  min-height: 120px;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  resize: vertical;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.5;
  transition: border-color 0.2s ease;
  background: white;
  display: block; /* Ensure it's a block element */
  margin: 0; /* Remove any default margins */
}

.rejection-textarea:focus {
  outline: none;
  border-color: #6a1b9a;
  box-shadow: 0 0 0 3px rgba(106, 27, 154, 0.1);
}

.rejection-textarea::placeholder {
  color: #9ca3af;
}

/* Modal Actions for Rejection Dialog */
.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.modal-actions button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.modal-actions button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Specific button styles for rejection dialog */
.rejection-dialog .reject-button {
  background: #ef4444;
  color: white;
  border: none;
}

.rejection-dialog .reject-button:hover:not(:disabled) {
  background: #dc2626;
}

.rejection-dialog .reject-button:disabled {
  background: #fca5a5;
}

.rejection-dialog .cancel-button {
  background: #e5e7eb;
  color: #374151;
  border: none;
}

.rejection-dialog .cancel-button:hover:not(:disabled) {
  background: #d1d5db;
}

/* Responsive styles for rejection dialog */
@media (max-width: 768px) {
  .rejection-form {
    padding: 1rem;
    margin: 1rem 0;
  }

  .rejection-textarea {
    min-height: 100px;
  }

  .modal-actions {
    flex-direction: column;
  }

  .modal-actions button {
    width: 100%;
  }

  .rejection-dialog .modal-content {
    margin: 1rem;
    padding: 1.5rem;
    max-height: calc(100vh - 2rem);
  }
}

/* Z-index handling for stacked modals */
.modal-overlay {
  z-index: 50;
}

.rejection-dialog .modal-overlay {
  z-index: 51;
}

/* Print styles */
@media print {
  .rejection-dialog,
  .rejection-form,
  .modal-actions {
    display: none !important;
  }
}