/* src/assets/styles/PaymentVerificationPage.css */

.payment-verification-page {
  min-height: 100vh;
  background-color: #f9fafb;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verification-card {
  max-width: 600px;
  width: 100%;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.status-icon {
  animation: fadeIn 0.5s ease-out;
}

.status-icon.pending {
  color: #6a1b9a;
  animation: spin 2s linear infinite;
}

.status-icon.confirmed {
  color: #059669;
}

.status-icon.rejected {
  color: #dc2626;
}

.status-message {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  animation: fadeIn 0.5s ease-out;
}

.status-message h2 {
  font-size: 1.875rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.5rem;
}

.status-message p {
  color: #6b7280;
  font-size: 1rem;
  line-height: 1.5;
}

.verification-steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;
  width: 100%;
  max-width: 400px;
}

.step {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f3f4f6;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.step.completed {
  background: #dcfce7;
}

.step.active {
  background: #f3e8ff;
  border: 1px solid #6a1b9a;
}

.step-number {
  width: 2rem;
  height: 2rem;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #6b7280;
}

.step.completed .step-number {
  background: #059669;
  color: white;
}

.step.active .step-number {
  background: #6a1b9a;
  color: white;
}

.step-text {
  font-weight: 500;
  color: #374151;
}

.ticket-numbers {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin: 1rem 0;
}

.ticket-numbers h3 {
  color: #374151;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

.ticket-numbers p {
  font-family: monospace;
  font-size: 1.125rem;
  color: #6a1b9a;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.action-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  font-size: 0.875rem;
  background: #6a1b9a;
  color: white;
}

.action-button:hover {
  background: #4a148c;
  transform: translateY(-1px);
}

.action-button.retry {
  background: #6a1b9a;
}

.action-button.support {
  background: #4b5563;
}

.error-icon {
  color: #dc2626;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 640px) {
  .verification-card {
    padding: 1.5rem;
  }

  .status-message h2 {
    font-size: 1.5rem;
  }

  .action-buttons {
    flex-direction: column;
  }

  .action-button {
    width: 100%;
  }
}