/* src/assets/styles/adminSections/ConfirmedPayments.css */
@import '../base.css';

/* Main Container */
.confirmed-payments {
  height: calc(100vh - var(--bottom-nav-height));
  overflow-y: auto;
  padding: 1.5rem;
  padding-bottom: calc(var(--bottom-nav-height) + 1rem);
  position: relative;
  background: var(--background);
}

/* Header Section */
.confirmed-header {
  margin-bottom: 1.5rem;
}

.confirmed-header h1 {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--foreground);
  margin-bottom: 0.5rem;
}

.confirmed-header p {
  color: var(--muted-foreground);
  font-size: 0.875rem;
}

/* Controls Section */
.controls-section {
  position: sticky;
  top: 0;
  background: var(--background);
  padding: 0.5rem 0;
  z-index: 20;
  margin-bottom: 1.5rem;
}

.search-box {
  margin-bottom: 1rem;
}

.search-box input {
  width: 100%;
  padding: 0.875rem 1rem;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background: white;
}

.search-box input:focus {
  outline: none;
  border-color: #6a1b9a;
  box-shadow: 0 0 0 2px rgba(106, 27, 154, 0.1);
}

.search-box input::placeholder {
  color: var(--muted-foreground);
}

/* Filter Buttons */
.filter-buttons {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  padding-bottom: 0.25rem;
  -webkit-overflow-scrolling: touch;
}

.filter-buttons::-webkit-scrollbar {
  display: none;
}

.filter-button {
  padding: 0.75rem 1.25rem;
  border: 1px solid #6a1b9a;
  background: transparent;
  border-radius: var(--radius);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  color: #6a1b9a;
  font-weight: 500;
  flex-shrink: 0;
}

.filter-button.active {
  background: #6a1b9a;
  color: white;
  border-color: #6a1b9a;
  box-shadow: 0 2px 4px rgba(106, 27, 154, 0.2);
}

.filter-button:hover:not(.active) {
  background: #6a1b9a;
  color: white;
  opacity: 0.9;
}

/* Table Container */
.payments-table-container {
  background: white;
  border-radius: var(--radius);
  border: 1px solid var(--border);
  overflow: hidden;
  margin-bottom: 1rem;
}

.table-scroll {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.payments-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 750px;
}

.payments-table th {
  background: #6a1b9a;
  color: white;
  text-align: left;
  padding: 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  white-space: nowrap;
  position: sticky;
  top: 0;
}

.payments-table th:first-child {
  border-top-left-radius: var(--radius);
}

.payments-table th:last-child {
  border-top-right-radius: var(--radius);
}

.payments-table td {
  padding: 1rem;
  border-bottom: 1px solid var(--border);
  font-size: 0.875rem;
  background: white;
}

.payments-table tr:hover td {
  background-color: #f5f3ff;
}

/* View Button */
.view-button {
  padding: 0.5rem;
  background: #6a1b9a;
  color: white;
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-button:hover {
  background: #581b7e;
  transform: translateY(-1px);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 100;
}

.modal-content {
  background: white;
  border-radius: var(--radius);
  padding: 2rem;
  max-width: 600px;
  width: 100%;
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
}

/* Empty State */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  text-align: center;
  color: var(--muted-foreground);
  background: white;
  border-radius: var(--radius);
  margin: 1rem 0;
}

.empty-state svg {
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
  color: var(--muted-foreground);
}

/* Loading State */
.loading-state {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--border);
  border-top-color: #6a1b9a;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.loading-text {
  color: #6a1b9a;
  font-size: 0.875rem;
  font-weight: 500;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .confirmed-payments {
    padding: 1rem;
    padding-bottom: calc(var(--bottom-nav-height) + 1rem);
  }

  .confirmed-header h1 {
    font-size: 1.5rem;
  }

  .filter-button {
    padding: 0.625rem 1rem;
  }

  .payments-table-container {
    margin: 0 -1rem;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .table-scroll {
    padding: 0 1rem;
  }

  .payments-table th,
  .payments-table td {
    padding: 0.75rem;
  }

  .modal-content {
    margin: 1rem;
    padding: 1.5rem;
    max-height: calc(100vh - 2rem);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}