/* SelectNumbersPage.css */
.select-numbers-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #f9fafb;
  padding-bottom: calc(5rem + 80px);
}

/* Loading State */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  backdrop-filter: blur(4px);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.spinner-ring {
  position: relative;
  width: 64px;
  height: 64px;
}

.spinner-ring::before,
.spinner-ring::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
  animation: spinner-rotate 1.5s cubic-bezier(0.76, 0.35, 0.2, 0.7) infinite;
}

.spinner-ring::before {
  width: 100%;
  height: 100%;
  border-top-color: #6a1b9a;
  border-right-color: #6a1b9a;
  animation-delay: -0.4s;
}

.spinner-ring::after {
  width: 80%;
  height: 80%;
  border-bottom-color: #9c4dcc;
  border-left-color: #9c4dcc;
  top: 10%;
  left: 10%;
}

@keyframes spinner-rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  color: #6b7280;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  opacity: 0;
  animation: text-fade 1s ease-in forwards;
}

@keyframes text-fade {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Raffle Info Card */
.raffle-info-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
}

.raffle-image {
  width: 300px;
  height: 100%;
  object-fit: cover;
}

.raffle-details {
  padding: 1.5rem;
  flex: 1;
}

.raffle-details h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.5rem;
}

.description {
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

/* Stats Grid */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  background: #f9fafb;
  border-radius: 8px;
}

.stat-icon {
  color: #6a1b9a;
  margin-bottom: 0.5rem;
}

.stat-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.stat-info .label {
  font-size: 0.75rem;
  color: #6b7280;
  width: 100%;
}

.stat-info .value {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  width: 100%;
}

/* Numbers Selection Card */
.numbers-selection-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
  overflow: visible;
}

.numbers-selection-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1.5rem;
}

/* Numbers Grid */
.numbers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 0.5rem;
  padding: 1rem;
  position: relative;
  z-index: 1;
}

/* Base Button Styles */
.number-button {
  aspect-ratio: 1;
  border: 2px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #374151;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  z-index: 2;
}

/* Available State */
.numbers-grid .number-button.available {
  background: white;
  border-color: #e5e7eb;
  color: #374151;
}

.numbers-grid .number-button.available:hover:not(:disabled) {
  background: #f3f4f6;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-color: #6a1b9a;
}

/* Selected State */
.numbers-grid .number-button.selected {
  background: #6a1b9a;
  color: white;
  border-color: #4a1b9a;
  transform: scale(1.05);
  box-shadow: 0 4px 6px -1px rgba(106, 27, 154, 0.3);
  font-weight: 700;
}

/* Sold State */
.numbers-grid .number-button.sold {
  background: #ef4444 !important;
  background-image: linear-gradient(45deg, #ef4444, #dc2626) !important;
  color: white !important;
  border-color: #dc2626 !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
  pointer-events: none !important;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  transform: scale(0.98) !important;
}

/* Reserved State */
.numbers-grid .number-button.reserved {
  background: #9ca3af !important;
  background-image: linear-gradient(45deg, #9ca3af, #6b7280) !important;
  color: white !important;
  border-color: #6b7280 !important;
  cursor: not-allowed !important;
  opacity: 0.9 !important;
  pointer-events: none !important;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  transform: scale(0.98) !important;
}

/* Disabled State */
.numbers-grid .number-button:disabled:not(.sold):not(.reserved) {
  background: #e5e7eb !important;
  color: #9ca3af !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
  transform: scale(0.98) !important;
  opacity: 0.5 !important;
}

/* Tooltips */
.number-button.sold::before,
.number-button.reserved::before {
  content: attr(title);
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.number-button.sold::after,
.number-button.reserved::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border: 5px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.number-button.sold:hover::before,
.number-button.sold:hover::after,
.number-button.reserved:hover::before,
.number-button.reserved:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Sticky Summary */
.sticky-summary {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #e5e7eb;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 50;
}

.summary-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.summary-details {
  flex: 1;
}

.total-amount {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
}

.selected-numbers {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}

.continue-button {
  background: #6a1b9a;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 160px;
  text-align: center;
}

.continue-button:hover:not(:disabled) {
  background: #581b7c;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(106, 27, 154, 0.2);
}

.continue-button:disabled {
  background: #e5e7eb;
  color: #9ca3af;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Error Message */
.error-message {
  background: #fee2e2;
  border: 1px solid #fecaca;
  color: #991b1b;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

/* No Raffle State */
.no-raffle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
  background-color: #f9fafb;
}

.no-raffle-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.no-raffle-icon {
  color: #9ca3af;
  margin-bottom: 1.5rem;
}

.no-raffle-card h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.75rem;
}

.no-raffle-card p {
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Media Queries */
@media (max-width: 768px) {
  .select-numbers-page {
    padding: 1rem;
    padding-bottom: calc(5rem + 100px);
  }

  .raffle-info-card {
    flex-direction: column;
  }

  .raffle-image {
    width: 100%;
    height: 200px;
  }

  .stats-grid {
    grid-template-columns: 1fr;
  }

  .numbers-grid {
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 0.25rem;
    padding: 0.5rem;
  }

  .number-button {
    min-height: 44px;
    min-width: 44px;
  }

  .summary-content {
    flex-direction: column;
    text-align: center;
    padding: 1rem;
  }

  .continue-button {
    width: 100%;
    min-width: unset;
  }

  .selected-numbers {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .numbers-grid {
    grid-template-columns: repeat(auto-fill, minmax(45px, 1fr));
  }

  .number-button {
    font-size: 0.75rem;
  }

  .total-amount {
    font-size: 1.125rem;
  }

  .selected-numbers {
    font-size: 0.75rem;
  }

  .raffle-details {
    padding: 1rem;
  }

  .stat-item {
    padding: 0.75rem;
  }

  .loading-text {
    font-size: 1rem;
  }

  .select-numbers-page {
    padding-bottom: calc(5rem + 120px);
  }
}

/* Print styles */
@media print {
  .sticky-summary {
    position: static;
    box-shadow: none;
    border-top: 1px solid #e5e7eb;
  }

  .continue-button {
    display: none;
  }

  .select-numbers-page {
    background: white;
  }

  .loading-overlay,
  .spinner-ring {
    display: none;
  }
}

/* Touch device optimization */
@media (hover: none) {
  .number-button::before,
  .number-button::after {
    display: none;
  }

  .numbers-grid .number-button.available:hover {
    transform: none;
    box-shadow: none;
  }
}

/* Accessibility focus styles */
.number-button:focus-visible {
  outline: 2px solid #6a1b9a;
  outline-offset: 2px;
}

/* Animation for tooltip */
@keyframes tooltip-fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* High contrast mode support */
@media (forced-colors: active) {
  .numbers-grid .number-button.sold {
    forced-color-adjust: none;
    background: #ef4444 !important;
    border: 2px solid #dc2626 !important;
  }
  
  .numbers-grid .number-button.reserved {
    forced-color-adjust: none;
    background: #9ca3af !important;
    border: 2px solid #6b7280 !important;
  }

  .number-button:focus-visible {
    outline: 2px solid CanvasText;
  }
}

/* Update the status-specific styles */
.number-button.reserved {
  background: #9ca3af !important;
  background-image: linear-gradient(45deg, #9ca3af, #6b7280) !important;
  color: white !important;
  border-color: #6b7280 !important;
  cursor: not-allowed !important;
  opacity: 0.9 !important;
  pointer-events: none !important;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  transform: scale(0.98) !important;
}

.number-button.sold {
  background: #ef4444 !important;
  background-image: linear-gradient(45deg, #ef4444, #dc2626) !important;
  color: white !important;
  border-color: #dc2626 !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
  pointer-events: none !important;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  transform: scale(0.98) !important;
}

/* Ensure disabled buttons can't be clicked */
.number-button:disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
}